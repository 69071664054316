@import "../app/vars";

.page-session-print {
    display: table;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    .block-container {
        display: table-cell;
        position: relative;
    }

    /* HEADER */
    .block-header {
        display: table-row;
        height: 5.5em;

        .media {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .title {
            margin: 0;
        }

        i {
            width: 2em;
            text-align: center;
        }
    }

    /* MAP */
    .block-map {
        display: table-row;
        height: 40%;

        .layout-map {
            background: transparent;
            padding: 0;
        }

        .jvectormap-container {
            text-align: center;
        }

        svg.frozen {
            max-width: 100%;
            max-height: 100%;
            border: 3px dashed @color-grey-light;
        }
    }

    /* LIST */
    .block-list {
        display: table-row;

        .booking-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;

            position: absolute;
            top: 3.5em;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .booking {
            width: ~"calc(25% - 10px)";
            margin-top: 0.4em;
            margin-left: 5px;
            margin-right: 5px;
            border-bottom: 2px dotted @color-grey-light;
        }
    }

    /* FOOTER */
    .block-footer {
        display: table-row;
        height: 2em;

        p {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;

            text-align: center;
        }
    }
}
